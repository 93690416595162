import React, { useState } from 'react';
import { ethers } from 'ethers';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import AccessPage from './AccessPage';
import './App.css';
import './AccessPage.css'
import Main from './Main';

function App() {

  return (

    <div className="App">

        <header className="new-header-class">
            <div className="title-background">
                <h1>TGA Insight HUB</h1>
                <p>BLOCKCHAIN REALLABOR POAP GATED ACCESS</p>
            </div>


        </header>

        <Routes>
          <Route path="*" element={<Main />} />
          <Route path='/access' element={<AccessPage />} />
        </Routes>


    </div>

  )
}


export default App;